export default [
    {
        "page": "meta_information",
        "model": "meta_information",
        "name": "street_nr_asset",
        "type": "text",
        "is_required": true,
        "category": "Standort der Anlage",
        "label": "Straße und Hausnummer"
    },
    {
        "page": "meta_information",
        "model": "meta_information",
        "name": "postcode_asset",
        "type": "text",
        "is_required": true,
        "unit": " ",
        "category": "Standort der Anlage",
        "label": "Postleitzahl",
        "description": "Ihre Postleitzahl hilft uns, die richtigen Förderprogramme zu finden und die Potentiale erneuerbarer Energien genauer abzuschätzen.",
        "min_err": "Die niedrigste Postleitzahl ist 01067 in Deutschland.",
        "max_err": "Die höchste Postleitzahl ist 99998 in Deutschland.",
        "pattern": "^[0-9]{5}$",
        "pattern_err": "Stellen Sie sicher, dass dieser Wert höchstens 5 Zeichen hat."
    },
    {
        "page": "number_of_modules_pv",
        "model": "further_information",
        "name": "number_of_modules_pv",
        "type": "number",
        "is_required": true,
        "placeholder": "0-100000",
        "category": "Ihre Bestandsanlage",
        "label": "Anzahl Module",
        "description": "Hier geben Sie bitte die Anzahl der verbauten Module an",
        "min": 0.0,
        "max": 100000.0,
        "max_err": "Sind Sie sicher? Das ist besonders hoch und könnte zu fehlerhaften Ergebnissen führen."
    },
    {
        "page": "watt_per_module_pv",
        "model": "further_information",
        "name": "watt_per_module_pv",
        "type": "number",
        "is_required": true,
        "placeholder": "0-450",
        "unit": "W",
        "category": "Ihre Bestandsanlage",
        "label": "Nennleistung Watt/Modul ",
        "description": "Hier geben Sie bitte die Nennleistug des Einzelmoduls in kW an",
        "min": 0.0,
        "max": 450.0,
        "max_err": "Sind Sie sicher? Das ist besonders hoch und könnte zu fehlerhaften Ergebnissen führen."
    },
    {
        "page": "max_power_pv",
        "model": "further_information",
        "name": "max_power_pv",
        "type": "number",
        "is_required": true,
        "placeholder": "0-100000",
        "unit": "kWp",
        "category": "Ihre Bestandsanlage",
        "label": "kWp Leistung der PV Anlage",
        "description": "Hier geben Sie bitte die Gesamtleistung (Nennleistung)  ihrer PV-Anlage in kWp an",
        "min": 0.0,
        "max": 100000.0,
        "min_err": "Sind Sie sicher? Das ist besonders niedrig und könnte zu fehlerhaften Ergebnissen führen.",
        "max_err": "Sind Sie sicher? Leider können wir mit derart großen PV Anlagen derzeit noch keine Analyse machen (Max. 2000 kWp)"
    },
    {
        "page": "area_installed_pv",
        "model": "further_information",
        "name": "area_installed_pv",
        "type": "number",
        "is_required": true,
        "placeholder": "0-100000",
        "unit": "m²",
        "category": "Ihre Bestandsanlage",
        "label": "Modulfläche in m²",
        "description": "Hier geben Sie bitte die ungefähre Modulfläche ihrer PV-Anlage in m² an",
        "min": 0.0,
        "max": 100000.0,
        "min_err": "Sind Sie sicher? Das ist besonders niedrig und könnte zu fehlerhaften Ergebnissen führen.",
        "max_err": "Sind Sie sicher? Leider können wir mit derart großen PV Anlagen derzeit noch keine Analyse machen (Max. 10000 m2)"
    },
    {
        "page": "startfeedin_pv",
        "model": "further_information",
        "name": "startfeedin_pv",
        "type": "date",
        "is_required": true,
        "category": "Ihre Bestandsanlage",
        "label": "Start Einspeisedatum EEG",
        "description": "Hier geben Sie bitte das Datum der ersten Einspeisung Ihrer PV-Anlage an. Diese Information finden Sie in den Unterlagen Ihrer PV Anlage. Im ersten Schritt reicht auch hier ein ungefähres Datum, welches die EEG Vergütungshöhe Ihrer Bestandsanlage definiert. "
    },
    {
        "page": "eeg_subsidy_old_pv",
        "model": "further_information",
        "name": "eeg_subsidy_old_pv",
        "type": "number",
        "is_required": true,
        "placeholder": "0-50",
        "unit": "Cent",
        "category": "Ihre Bestandsanlage",
        "label": "EEG-Vergütung Ihrer Anlage",
        "description": "Hier geben Sie bitte die Höhe der gewährten EEG Vergütung je kWh an. Diese Information finden Sie in den Unterlagen Ihrer PV Anlage, oder in der jährlichen Abrechnung Ihres Netzbetreibers. Im ersten Schritt können Sie mit der hier angezeigten Tabelle und einem ungefähren Datum, die EEG Vergütung Ihrer Bestandsanlage von damals ermitteln. ",
        "min": 0.0,
        "max": 50.0
    },
    {
        "page": "total_yield_pv",
        "model": "further_information",
        "name": "total_yield_pv",
        "type": "number",
        "is_required": true,
        "placeholder": "1000-2000000",
        "unit": "kWh",
        "category": "Ihre Bestandsanlage",
        "label": "Stromproduktion/Jahr Ihrer Anlage",
        "description": "Ihr jährlicher Gesamtertrag durch PV in kWh.",
        "min": 1000.0,
        "max": 2000000.0,
        "min_err": "Sind Sie sicher? Das ist besonders niedrig und könnte zu fehlerhaften Ergebnissen führen.",
        "max_err": "Sind Sie sicher? Das ist besonders hoch und könnte zu fehlerhaften Ergebnissen führen."
    },
    {
        "page": "total_consumption_el",
        "model": "electricity_information",
        "name": "total_consumption_el",
        "type": "number",
        "is_required": true,
        "placeholder": "5000-2000000",
        "unit": "kWh",
        "category": "Ihre Bestandsanlage",
        "label": "Jährlicher Strombedarf",
        "description": "Ihr jährlicher Gesamtenergieverbrauch an Elektrizität in kWh. Diesen finden Sie auf Ihrer Jahresstromrechnung, Überschlägig können sie diesen errechnen, wenn Sie den monatlichen Abschlag für Strom in EURO  mit 4 multiplizieren (25 cent je kwh) und dann x 12 Monate rechnen.",
        "min": 5000.0,
        "max": 2000000.0,
        "min_err": "Sind Sie sicher? Das ist besonders niedrig und könnte zu fehlerhaften Ergebnissen führen.",
        "max_err": "Sind Sie sicher? Das ist besonders hoch und könnte zu fehlerhaften Ergebnissen führen."
    },
    {
        "page": "has_roof_free",
        "model": "transient",
        "name": "has_roof_free",
        "type": "checkbox",
        "is_required": false,
        "category": "Verfügbare Fläche",
        "label": "Gibt es neben der Altanlage noch zusätzliche nicht bebaute Flächen?"
    },
    {
        "page": "add_area_roof_pv",
        "model": "further_information",
        "name": "add_area_roof_pv",
        "type": "number",
        "is_required": false,
        "placeholder": "0-10000",
        "unit": "m²",
        "category": "Verfügbare Fläche",
        "label": "Zusätzlich verfügbare Fläche (m²)",
        "description": "Hier geben Sie bitte die Gesamtfläche an, die zusätzlich verfügbar wäre für eine zukünftige Belegung mit PV-Modulen (Restflächen, zusätzliche Dächer - die Flächen müssen nicht unbedingt zusammenhängend belegbar sein). Eine Abschätzung reicht uns hier.",
        "min": 0.0,
        "max": 10000.0,
        "max_err": "Sind Sie sicher? Das ist besonders hoch und könnte zu fehlerhaften Ergebnissen führen."
    },
    {
        "page": "load_profile",
        "model": "load_profile",
        "name": "daily_profile",
        "type": "array",
        "is_required": true,
        "category": "Energieprofil",
        "label": "Lastgangprofil (Tag)",
        "description": "Wie produzieren Sie an einem gewöhnlichen Tag? Bei ihrem Profil kann es sinnhaft sein Teile der Stromerzegung einzuspeisen. Daher benötigen wir Informationen zu ihrem Energieprofil"
    },
    {
        "page": "load_profile",
        "model": "load_profile",
        "name": "monthly_profile",
        "type": "array",
        "is_required": true,
        "category": "Energieprofil",
        "label": "Lastgangprofil (Monat)",
        "description": "Wie produzieren Sie an einem gewöhnlichen Jahr?  Bei ihrem Profil kann es sinnhaft sein Teile der Stromerzegung einzuspeisen. Daher benötigen wir Informationen zu ihrem Energieprofil"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "street_nr_private",
        "type": "text",
        "is_required": true,
        "category": "Kundendaten",
        "label": "Straße und Hausnummer"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "postcode_private",
        "type": "text",
        "is_required": true,
        "category": "Kundendaten",
        "label": "Postleitzahl",
        "pattern": "^[0-9]{5}$",
        "pattern_err": "Stellen Sie sicher, dass dieser Wert höchstens 5 Zeichen hat."
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "city",
        "type": "text",
        "is_required": true,
        "category": "Kundendaten",
        "label": "Stadt"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "company_name",
        "type": "text",
        "is_required": false,
        "category": "Kundendaten",
        "label": "Unternehmen"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "firstname",
        "type": "text",
        "is_required": true,
        "category": "Kundendaten",
        "label": "Vorname"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "lastname",
        "type": "text",
        "is_required": true,
        "category": "Kundendaten",
        "label": "Name"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "email",
        "type": "email",
        "is_required": true,
        "category": "Kundendaten",
        "label": "Email"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "telephone",
        "type": "tel",
        "is_required": false,
        "category": "Kundendaten",
        "label": "Telefonnummer"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "notes",
        "type": "text",
        "is_required": false,
        "category": "Kundendaten",
        "label": "Notizen",
        "description": "Haben Sie noch weitere Informationen oder Besonderheiten, von denen wir Wissen sollten? Lassen Sie uns diese hier wissen!"
    }
]