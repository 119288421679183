import React, { ComponentProps, HTMLInputTypeAttribute, memo, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Field } from '@/types';

const numericValidationRule = {
  validate: (value: string) => {
    if (!value) return true; // allow empty value
    if (value.match(/^[0-9]+[,.]?[0-9]*$/)) {
      return true;
    }
    return 'Bitte geben Sie eine gültige Zahl ein';
  },
  setValueAs: (value: string) => (value ? parseFloat(value.replace(',', '.')) : undefined),
};

const integerValidationRule = {
  validate: (value: string) => {
    if (!value) return true; // allow empty value
    if (value.match(/^[0-9]+$/)) {
      return true;
    }
    return 'Bitte geben Sie eine gültige Zahl ein';
  },
  setValueAs: (value: string) => (value ? parseInt(value) : undefined),
};

const dateValidationRule = {
  validate: (value: string) => {
    if (!value) return true; // allow empty value
    if (!value.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
      return 'Bitte geben Sie eine gültige yyyy-mm-dd Datum ein';
    }
    return true;
  },
};

const emailValidationRule = {
  validate: (value: string) => {
    const basePattern = /^[^@]+@[^@]+\.[^@]+$/;
    return basePattern.test(value) || 'Bitte geben Sie eine gültige E-Mail-Adresse ein';
  },
};

const telValidationRule = {
  validate: (value: string) => {
    const basePattern = /^[0-9+ ]{1,20}$/;
    return basePattern.test(value) || 'Bitte geben Sie eine gültige Telefonnummer ein';
  },
};

const REQUIRED_MESSAGE = 'Bitte geben Sie eine gültige Antwort ein';

const rulesMapper: Partial<Record<HTMLInputTypeAttribute, Record<string, any>>> = {
  number: numericValidationRule,
  float: numericValidationRule,
  integer: integerValidationRule,
  date: dateValidationRule,
  email: emailValidationRule,
  tel: telValidationRule,
};

interface Props extends ComponentProps<typeof Controller> {
  q: Omit<Field, 'name' | 'type'>;
  type: Field['type'];
  name: Field['name'];
}

const FieldController = ({ name, q, type, render, defaultValue }: Props) => {
  const { control, watch } = useFormContext();

  const rules = useMemo(
    () =>
      ({
        required: q.is_required && REQUIRED_MESSAGE,
        ...(q.min && { min: { value: q.min, message: q.min_err } }),
        ...(q.max && { max: { value: q.max, message: q.max_err } }),
        ...(q.pattern && { pattern: { value: new RegExp(q.pattern), message: q.pattern_err } }),
        ...rulesMapper[type],
      }) as ComponentProps<typeof Controller>['rules'],
    [q, type],
  );

  return <Controller defaultValue={defaultValue} control={control} name={name} rules={rules} render={render} />;
};

export default memo(FieldController);
