/*
array of pages, questions, in prompt order.
this is mostly JSON but defined in JS to permit logic in page handling.
*/

import fields from '@/RepoweringFields';
import { API } from '@constants/api';
import { ConfigType } from '@/types';
import { findPageIndex, generatedPages } from '@/config/helpers';

const pages = Array.from(generatedPages(fields).values()) as ConfigType['pages'];

export const skipRules = {
  has_roof_free: (data: any) => {
    return findPageIndex({
      redirectPage: 'load_profile',
      isNextPageAccessible: data.transient.has_roof_free,
      pages,
    });
  },
  add_area_information: (data: any) => {
    return findPageIndex({
      redirectPage: 'address_information',
      isNextPageAccessible: data.futher_information.add_area_information,
      pages,
    });
  },
};

const Config: ConfigType = {
  report_api_url: API.calculations.repowering,
  pages,
  skipRules,
};

export default Config;
