import React, { FC, memo } from 'react';
import Title from '@components/load-profile/Title';
import PercentLabelsY from '@components/load-profile/PercentLabelsY';
import { Controller, useFormContext } from 'react-hook-form';
import LoadProfileWidget from '@components/load-profile/LoadProfileWidget';
import { Field } from '@/types';

const LoadProfileDailyField: FC<Field> = ({ description, ...props }) => {
  const { control } = useFormContext();

  return (
    <div>
      {description}
      <Title
        title={'Lastgangprofil Tag'}
        tooltip={
          'Wie produzieren Sie an einem durchschnittlichen Produktionstag? Bei Ihrem Profil kann es sinnhaft sein, Teile der Stromerzegung einzuspeisen. Daher benötigen wir Informationen zu ihrem Energieverbauch über den Tag bzw. das Jahr hinweg.'
        }
      />

      <div className="flex flex-row">
        <PercentLabelsY />
        <Controller
          control={control}
          render={({ field, fieldState, formState }) => {
            const daily_profile = field.value.split(',');

            return (
              <LoadProfileWidget
                onChange={field.onChange}
                profile_name={field.name}
                profile={daily_profile}
                text_labels={daily_profile.map((_: number, i: number) => i)}
              />
            );
          }}
          name={`${props.model}.${props.name}`}
        />
      </div>
    </div>
  );
};

export default memo(LoadProfileDailyField);
