import React from 'react';

const FileLogo = () => {
  return (
    <>
      <svg width="60" height="76" viewBox="0 0 60 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M57.5 46.25V36.625C57.5 33.3429 56.1962 30.1953 53.8754 27.8746C51.5547 25.5538 48.4071 24.25 45.125 24.25H39.625C38.531 24.25 37.4818 23.8154 36.7082 23.0418C35.9346 22.2682 35.5 21.219 35.5 20.125V14.625C35.5 11.3429 34.1962 8.19532 31.8754 5.87455C29.5547 3.55379 26.4071 2.25 23.125 2.25H16.25M19 54.5V57.25M30 46.25V57.25M41 38V57.25M24.5 2.25H6.625C4.348 2.25 2.5 4.098 2.5 6.375V69.625C2.5 71.902 4.348 73.75 6.625 73.75H53.375C55.652 73.75 57.5 71.902 57.5 69.625V35.25C57.5 26.4979 54.0232 18.1042 47.8345 11.9155C41.6458 5.72678 33.2521 2.25 24.5 2.25Z"
          stroke="var(--document-logo-color)"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default FileLogo;
