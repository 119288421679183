import React, { FC, memo } from 'react';

interface Props {
  error?: string | null;
}

const InputError: FC<Props> = ({ error }) => {
  return (
    <div className="text-sm text-error peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">{error || ' '}</div>
  );
};

export default memo(InputError);
