import { useState } from 'react';
import ApiService from '@api/index';
import { MutationDataHookResult, PageConfig } from '@/types';
import { METHODS } from '@constants/api';

type ResponseData = Record<string, any>;

const usePostData = (): MutationDataHookResult<ResponseData> => {
  const [data, setData] = useState<ResponseData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isFetched, setIsFetched] = useState<boolean>(false);

  const postData = async (model: string, bodyData: PageConfig['save_model']) => {
    setLoading(true);
    setIsFetched(true);
    setError(null);

    try {
      const endpoint = `/${model}/`;
      const response = await ApiService.request<ResponseData>({ endpoint, method: METHODS.POST, body: bodyData });
      setData(response);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return [postData, { data, loading, error, isFetched }];
};

export default usePostData;
