import MainContainer from '@shared/MainContainer';
import SurveySubtitle from '@components/SurveySubtitle';
import CategoryTitle from '@components/CategoryTitle';
import ProgressBar from '@components/ProgressBar';
import { FormProvider, useForm } from 'react-hook-form';
import Form from '@components/form/Form';
import { CURRENT_CONFIG } from '@/config';
import React, { useCallback, useEffect, useState } from 'react';
import ArrowRight from '@components/ArrowRight';
import ChevronBack from '@components/ChevronBack';
import ArrowLeft from '@components/ArrowLeft';
import { initial_daily, initial_monthly } from '@components/load-profile/helpers';
import { v7 as uuidv7 } from 'uuid';
import usePostData from '@hooks/usePostData';
import useGenerateReport from '@hooks/useGenerateReport';
import { getDefaultValues } from '@/helpers/getDefaultValues';
import Report from '@components/Report';
import { useTheme } from '@/context/ThemeProvider';
import * as Sentry from "@sentry/react";

const pages = CURRENT_CONFIG.pages;

function App() {
  const { theme } = useTheme();
  const [page, setPage] = useState(0);
  const [pageHistory, setPageHistory] = useState<number[]>([]); // Tracks all visited pages
  const [sendModels, { loading: loadingModels, isFetched, error: modelsError }] = usePostData();
  const [generateReport, { data: report, error: reportError }] = useGenerateReport();
  const currentPage = pages[page];

  const form = useForm<Record<string, string | Record<string, any>>>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
    defaultValues: {
      uuid: uuidv7(),
      ...getDefaultValues(pages),
      load_profile: {
        daily_profile: initial_daily.join(','),
        monthly_profile: initial_monthly.join(','),
      },
    },
  });

  const onNext = useCallback(
    (d: Record<string, string | Record<string, any>>) => {
      if (page >= pages.length - 1) {
        return;
      }

      const nextStep = CURRENT_CONFIG.skipRules[currentPage.name as keyof (typeof CURRENT_CONFIG)['skipRules']]?.(d);

      setPage(prev => {
        setPageHistory(p => [...p, prev]);
        return nextStep > 0 ? nextStep : prev + 1;
      });
    },
    [page, currentPage],
  );

  const onPrev = useCallback(() => {
    if (pageHistory.length) {
      setPage(pageHistory.at(-1)!);
      setPageHistory(prev => prev.slice(0, -1));
    }
  }, [pageHistory]);

  const progress = (100 * page) / pages.length || 1;

  const onSubmit = useCallback(
    async (data: Record<string, string | Record<string, any>>) => {
      const { uuid, transient, ...restData } = data;

      // Send data on the final step
      if (page >= pages.length - 1) {
        const models = Object.keys(restData);

        await Promise.all(
          models.map(m => {
            const model = restData[m];

            return sendModels(m, { ...(typeof model !== 'string' && model), uuid });
          }),
        );

        await generateReport(uuid);
        return;
      }

      onNext(data);
    },
    [page],
  );

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Enter') {
        form.handleSubmit(onSubmit)();
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [page]);

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
      <FormProvider {...form}>
        {isFetched ? (
          <Report error={modelsError || reportError} reportUrl={report?.url} />
        ) : (
          <div className="flex flex-col min-h-screen">
            <MainContainer className="prose some-name flex-grow">
              <SurveySubtitle />
              <CategoryTitle category={currentPage.category} name={currentPage.name} />
              <Form inputs={currentPage.qns} />
            </MainContainer>

            <footer className="w-full h-[90px] bg-white shadow-lg footer-border-top fixed bottom-0">
              <div className="h-full flex items-center justify-between my-0 mx-10 gap-1">
                <div className="flex justify-start">
                    <button

                      className={`btn-go-back ${theme !== 'arup' ? 'btn-secondary btn-width' : 'btn-black'}`}
                      onClick={onPrev}
                      color="secondary"
                      type="button"
                      {...page === 0 && {
                        style: {
                          visibility: 'hidden',
                        }
                      }}

                    >
                      {theme !== 'arup' ? (
                        <div className="flex gap-1 items-center">
                          <ChevronBack />
                          <p>Zurück</p>
                        </div>
                      ) : (
                        <ArrowLeft />
                      )}
                    </button>
                </div>
                <div className="flex-1 grow max-w-[560px]">
                  <ProgressBar progressLength={20} progress={progress} />
                </div>
                <div className="flex justify-end ">
                  <button className="btn-next next" type="submit" disabled={loadingModels}>
                    {theme !== 'arup' ? (
                      <p>Weiter</p>
                    ) : (
                      <div className="flex gap-2 items-center">
                        <p>Weiter</p>
                        <ArrowRight />
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </footer>
          </div>
        )}
      </FormProvider>
    </form>
  );
}

export default App;
