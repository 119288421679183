import { memo } from 'react';

const SurveySubtitle = () => (
  <div
    className="survey-subtitle"
    style={{ paddingBottom: '1rem', borderBottom: '2px solid var(--subtitle-color)', textAlign: 'end' }}
  >
    In wenigen Schritten zu Ihrer Energie-Analyse
  </div>
);

export default memo(SurveySubtitle);
