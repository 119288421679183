import React, { FC, memo } from 'react';
import SettingsLogo from '@components/logos/SettingsLogo';
import HouseLogo from '@components/logos/HouseLogo';
import ProgramsLogo from '@components/logos/ProgramsLogo';
import LightningLogo from '@components/logos/LightningLogo';
import AvatarLogo from '@components/logos/AvatarLogo';

const LOGOS = {
  'Ort der Anlage': <HouseLogo />,
  Bestandsanlage: <SettingsLogo />,
  Stromverbrauch: <SettingsLogo />,
  'Verfügbare Fläche': <ProgramsLogo />,
  Energieprofil: <LightningLogo />,
  Kundendaten: <AvatarLogo />,
};

interface Props {
  category: string;
  name: string;
}

const CategoryTitle: FC<Props> = ({ category, name }) => {
  return (
    <div className={`mt-12 pt-6`}>
      <div>{LOGOS[category as keyof typeof LOGOS] || <SettingsLogo />}</div>
      <div className=" flex flex-row items-center gap-2">
        <h2 className="font-bold text-[36px]">{category || name}</h2>
      </div>
    </div>
  );
};

export default memo(CategoryTitle);
