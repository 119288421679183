// Generated  2024-08-12T14:52:03.817239
export default
{
    "company_sector": [
        "Energie - Erd\u00f6l und Erdgas, Erneuerbare Energien, Stromerzeugung und -verteilung",
        "Materialien - Papier, Chemikalien, Baustoffe, Metall- und Bergbauindustrie",
        "Industrie - Maschinenbau, Bauwesen und Bauprodukte, Luft- und Raumfahrt, Verteidigung",
        "Konsumg\u00fcter - Lebensmittel und Getr\u00e4nke, Haushalts- und K\u00f6rperpflegeprodukte, Tabak",
        "Gesundheitswesen - Pharmazeutika, Biotechnologie, Medizintechnik, Gesundheitsdienstleistungen",
        "Finanzen - Banken, Versicherungen, Verm\u00f6gensverwaltung",
        "Informationstechnologie - Software, Hardware, IT-Dienstleistungen",
        "Kommunikationsdienste - Telekommunikation, Medien, Unterhaltung",
        "Versorger - Wasserver- und -entsorgung, Gasversorgung, Elektrizit\u00e4tsversorgung",
        "Immobilien - Wohnimmobilien, Gewerbeimmobilien, Immobilienentwicklung",
        "Konsumg\u00fcter und Dienstleistungen - Einzelhandel, Hotels, Restaurants und Freizeit, Textilien und Bekleidung",
        "Transport und Logistik - Schifffahrt, Luftfracht, Eisenbahn, Stra\u00dfen- und Logistikdienste",
        "Bildung - Primar- und Sekundarbildung, Hochschulbildung, Berufsausbildung und Weiterbildung",
        "Regierung und \u00f6ffentlicher Sektor - \u00d6ffentliche Verwaltung, Verteidigung, Kommunale Dienste",
        "Umwelt- und Naturschutz - Abfallmanagement, Recycling, Naturschutz und Forstwirtschaft",
        "Sonstige"
    ],
    "source_heat": [
        "Erdgas (Gasf\u00f6rmig)",
        "Fl\u00fcssiggas",
        "\u00d6l",
        "W\u00e4rmepumpe",
        "Solarthermie",
        "Fernw\u00e4rme",
        "Elektrische Heizung (Direkumwandlung)",
        "Geothermie",
        "Biomasse",
        "Wasserstoff",
        "Holzpellets",
        "Diesel",
        "Biodiesel"
    ],
    "unit_heat": [
        "kWh",
        "Liter",
        "kg",
        "m\u00b3"
    ],
    "fluid_type_process_heat": [
        "Wasser",
        "Dampf"
    ]
}
