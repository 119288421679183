import { useState } from 'react';
import ApiService from '@api/index';
import { MutationDataHookResult, PageConfig } from '@/types';
import { METHODS } from '@constants/api';

interface ReportResponse {
  url: string;
}

const useGenerateReport = (): MutationDataHookResult<ReportResponse> => {
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [data, setData] = useState<ReportResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const generateReport = async (uuid: string) => {
    setLoading(true);
    setIsFetched(true);
    setError(null);

    try {
      const endpoint = `/gridty_calculations/?uuid=${uuid}&user=SWT`;
      const response = await ApiService.request<ReportResponse>({ endpoint, method: METHODS.GET });
      setData(response);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return [generateReport, { data: data as ReportResponse, loading, error, isFetched }];
};

export default useGenerateReport;
