import { HttpMethod } from '@/types';
import { VITE_NODE_ENV } from '@utils/getEnv';

export const API = {
  baseUrl: VITE_NODE_ENV['VITE_APP_API_URL'],
  calculations: {
    gridty: '/gridty_calculations',
    repowering: '/repowering_calculations',
  },
};

export const METHODS: { [key in HttpMethod]: HttpMethod } = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
};
