type UnionFromArray<T> = T extends string[] ? never : T extends infer U ? U : never;

type StringRecord<T> = T extends string ? Record<T, string> : never;

type EnvVariables<T> = T extends readonly string[] ? Record<UnionFromArray<T>[number], string> : StringRecord<T>;

export const getEnvVariables = <T extends readonly string[] | string>(envVariables: T): EnvVariables<T> => {
  const variables: string[] = Array.isArray(envVariables) ? envVariables : [envVariables];

  return variables.reduce(
    (acc, next) => ({
      ...acc,
      [next]: import.meta.env[next],
    }),
    {} as EnvVariables<T>,
  );
};

export const VITE_NODE_ENV: Record<string, string> = getEnvVariables([
  'VITE_NODE_ENV',
  'VITE_REACT_APP_FLOW',
  'VITE_AUTH_TOKEN',
  'VITE_THEME',
  'VITE_ENABLE_THEME_DROPDOWN',
  'VITE_APP_API_URL',
]);
