import GridtyConfig from '@/config/gridty';
import RepoweringConfig from '@/config/repowering';
import { VITE_NODE_ENV } from '@utils/getEnv';

export const CONFIG_MAP = {
  gridty: GridtyConfig,
  repowering: RepoweringConfig,
};

export const CURRENT_CONFIG = CONFIG_MAP[VITE_NODE_ENV['VITE_REACT_APP_FLOW'] as keyof typeof CONFIG_MAP];
