import React, { memo } from 'react';

const PercentLabelsY = () => {
  return (
    <div className="flex flex-col justify-around items-end">
      <div className="flex items-center gap-1.5">
        <p className="text-[10px]">100%</p>
        <div
          className="w-2 h-0.5"
          style={{
            backgroundColor: '#D4D4D4',
          }}
        ></div>
      </div>
      <div className="flex items-center gap-1.5">
        <p className="text-[10px]">50%</p>
        <div
          className="w-2 h-0.5"
          style={{
            backgroundColor: '#D4D4D4',
          }}
        ></div>
      </div>
      <div className="flex items-center gap-1.5">
        <p className="text-[10px]">0%</p>
        <div
          className="w-2 h-0.5"
          style={{
            backgroundColor: '#D4D4D4',
          }}
        ></div>
      </div>
    </div>
  );
};

export default memo(PercentLabelsY);
