import React, { FC, memo } from 'react';
import InputError from '@components/form/InputError';
import InputLabel from '@components/form/InputLabel';
import FieldController from '@components/form/FieldController';
import { Field } from '@/types';

const Select: FC<Field> = props => {
  return (
    <FieldController
      name={`${props.model}.${props.name}`}
      q={props}
      type={props.type}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputLabel q={props}>
            <div className="relative">
              <select
                id={field.name}
                {...field}
                {...(error && { style: { borderColor: 'var(--input-selected-error) !important' } })}
                className="w-full rounded border border-base-300 p-3 mt-2 text-neutral-800 font-semibold"
              >
                <option value="">--Bitte wählen Sie--</option>

                {props.options?.map((option: string) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              {props.unit && (
                <span className="absolute right-2 text-accent" style={{ marginTop: '18px' }}>
                  {props.unit}
                </span>
              )}

              <div className="mt-2 mb-2 h-1">{error && <InputError error={error.message} />}</div>
            </div>
          </InputLabel>
        );
      }}
    />
  );
};

export default memo(Select);
