// Generated  Wed 14 Aug 2024 13:04:10 CEST
export default [
    {
        "page": "total_consumption_el",
        "model": "electricity_information",
        "name": "total_consumption_el",
        "type": "integer",
        "is_required": true,
        "placeholder": "1-10000000",
        "unit": "kWh",
        "category": "Jährlicher Strombedarf",
        "label": "Jährlicher Verbrauch",
        "description": "Ihr jährlicher Gesamtenergieverbrauch an Elektrizität in kWh.",
        "min": 1,
        "max": 10000000,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "av_price_el",
        "model": "electricity_information",
        "name": "av_price_el",
        "type": "float",
        "is_required": true,
        "placeholder": "1-100",
        "unit": "Cent / kWh",
        "category": "Energiepreis",
        "label": "Durchschn. Energiekosten",
        "description": "Ihr durchschnittlicher Gesamtstrompreis (inklusive aller Zulagen) in Cent/kWh. Sollte sich dieser in nächster Zeit stark ändern, geben Sie gerne bereits den erwarteten Wert an.",
        "min": 1,
        "max": 100,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "load_profile",
        "model": "load_profile",
        "name": "daily_profile",
        "type": "custom",
        "is_required": false,
        "category": "Energieprofil",
        "label": "Lastprofil über den Tag",
        "description": "Wir wissen: Nicht jeder Ihrer Produktionstage ist immer gleich. Hier geht es uns mehr darum, zu verstehen, wie sie Energie über einen durchschnittlichen Tag hinweg benötigen. Es ist hier also keine Perfektion benötigt, nur eine grobe Abschätzung."
    },
    {
        "page": "load_profile",
        "model": "load_profile",
        "name": "monthly_profile",
        "type": "custom",
        "is_required": false,
        "category": "Energieprofil",
        "label": "Lastprofil über das jahr",
        "description": "Betriebsferien, Weihnachtsurlaub, Schwankungen in Ihrem Produktionsbetrieb? Hier wollen wir erfahren, wir viel sie über das Jahr produzieren. Die Standardeinstellung ist hier Montag-Freitag, also 5 von 7 Tagen (= 71.5% der Zeit). Auch hier ist keine Perfektion gefragt, nur eine grobe Abschätzung. "
    },
    {
        "page": "grid_connection_power",
        "model": "electricity_information",
        "name": "grid_connection_power",
        "type": "float",
        "is_required": true,
        "category": "Netzanschluss",
        "label": "Ihre Netzanschlussleistung",
        "unit": "kW",
        "placeholder": "1-100000",
        "description": "Ihre Netzanschlussleistung ermöglicht Einschätzungen zu Ihren Potentialen von dynamischen Stromnetzen zu profitieren.",
        "min": 1,
        "max": 100000,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
      },
      {
        "page": "postcode_asset",
        "model": "meta_information",
        "name": "postcode_asset",
        "type": "integer",
        "is_required": true,
        "category": "Ort der Anlage",
        "label": "Postleitzahl des Standorts",
        "description": "Ihre Postleitzahl hilft uns, die richtigen Förderprogramme zu finden und die Potentiale erneuerbarer Energien genauer abzuschätzen.",
        "min_err": "Die niedrigste Postleitzahl ist 01067 in Deutschland.",
        "max_err": "Die höchste Postleitzahl ist 99998 in Deutschland.",
        "pattern": "^[0-9]{5}$",
        "pattern_err": "Stellen Sie sicher, dass dieser Wert höchstens 5 Zeichen hat."
      },
    {
        "page": "headcount",
        "model": "meta_information",
        "name": "headcount",
        "type": "integer",
        "is_required": true,
        "placeholder": "1-1000",
        "category": "Ihr Unternehmen",
        "label": "Anzahl Mitarbeiter",
        "description": "Bestimmte Abschätzungen können wir über die Anzahl der Mitarbeiter:innen treffen.",
        "min": 1,
        "max": 1000,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "company_sector",
        "model": "meta_information",
        "name": "company_sector",
        "type": "select",
        "is_required": true,
        "placeholder": "Bitte wählen Sie",
        "category": "Ihr Unternehmen",
        "label": "Industriesektor",
        "description": "Einige unserer Abschätzungen sind sektorspezifisch. Bitte wählen Sie hier einen Sektor. "
    },
        {
        "page": "add_area_roof_pv",
        "model": "further_information",
        "name": "add_area_roof_pv",
        "type": "integer",
        "is_required": true,
        "category": "Weitere Flächen",
        "label": "Haben Sie zusätzliche Flächen für PV-Anlagen",
        "unit": "m²",
        "placeholder": "0-10000",
        "description": "Geben Sie hier vorhandene Dach- oder Freiflächen für die Nutzung von Solarthermie- oder PV-Anlagen an. Achten Sie darauf, dass diese Flächen nicht verschattet sind. Die Flächen müssen nicht unbedingt zusammenhängend belegbar sein. Eine Abschätzung reicht uns hier.",
        "min": 0,
        "max": 10000,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
      },
    {
        "page": "wants_charging",
        "model": "transient",
        "name": "wants_charging",
        "type": "checkbox",
        "is_required": false,
        "category": "Ladesäulen",
        "label": "Haben Sie Interesse an Ladesäulen? ",
        "description": "Mit Ladesäulen können Sie nicht nur Geld sparen sondern Teilweise auch dazuverdienen."
    },
    {
        "page": "has_public_parking",
        "model": "transient",
        "name": "has_public_parking",
        "type": "checkbox",
        "is_required": false,
        "category": "Ladesäulen",
        "label": "Haben Sie einen öffentlich zugänglichen Parkplatz nah an einem Wohngebiet oder einer Schnellstraße? ",
        "description": "Nah an Wohngebieten oder einer Schnellstraße/Autbahn gibt es oft Bedarf an Ladeinfrastruktur. Indem Sie Ladepunkte öffentlich zugänglich machen können sich die Säulen häufig deutlich schneller amortisieren."
    },
    {
        "page": "public_charging",
        "model": "further_information",
        "name": "count_normal_public_ev",
        "name": "count_normal_public_ev",
        "type": "integer",
        "is_required": true,
        "placeholder": "0-100",
        "category": "Ladesäulen",
        "label": "Anzahl öfftl. reguläre Ladesäule",
        "description": "Nah an Wohngebieten können reguläre (bis zu 22 kW) öffentliche Ladesäulen sich schnell amortisieren. Zum Beispiel, indem Autos über Nacht geladen werden können. Sie selber können dabei bestimmen, wann genau die Ladesäule auch öffentlich zugängig sein soll. ",
        "min": 0,
        "max": 100,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "public_charging",
        "model": "further_information",
        "name": "count_fast_public_ev",
        "type": "integer",
        "is_required": true,
        "placeholder": "0-100",
        "category": "Ladesäulen",
        "label": "Anzahl öfftl. Schnellladesäule",
        "description": "Nah an Schnellstraßen und Autobahnen können Schnellladesäulen (mit Ladeleistungen über 50 kW) sich schnell amortisieren. Dies liegt daran, dass sie eine hohe Nachfrage durch Reisende bedienen und Elektrofahrzeuge in kurzer Zeit aufladen können. Sie selber können dabei bestimmen, wann genau die Ladesäule auch öffentlich zugängig sein soll.",
        "min": 0,
        "max": 100,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "private_charging",
        "model": "further_information",
        "name": "count_normal_ev",
        "type": "integer",
        "is_required": true,
        "placeholder": "0-100",
        "category": "Ladesäulen",
        "label": "Anzahl reguläre Ladesäule",
        "description": "Vor allem für Ihre Mitarbeiterinnen und Mitarbeiter und Ihren internen Fuhrpark kann sich die Investition in interne reguläre (bis zu 22 kW) Ladeinfrastruktur lohnen. Als Rechenbeispiel: Bis 2030 wird erwartet, dass ca. 20 % der Autos batterieelektrisch betrieben sind. Bei 100 Autos auf ihrem Parkplatz wollen also Maximal 20 geladen werden. An einer Ladesäule können maximal 2 Autos gleichzeitig geladen werden. Es werden also maximal 10 Ladesäulen benötigt. Unser Tipp: Eher etwas konservativ starten und nach und nach ausbauen.",
        "min": 0,
        "max": 100,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "private_charging",
        "model": "further_information",
        "name": "count_fast_ev",
        "type": "integer",
        "is_required": true,
        "placeholder": "0-100",
        "category": "Ladesäulen",
        "label": "Anzahl Schnellladesäule",
        "description": "Interne Schnellladesäulen sind vor allem interessant, wenn Sie (größere) Nutzfahrzeuge damit laden wollen. Ein e-LKW kann beispielsweise in 4-8 Stunden geladen werden. Aber auch kleine Transporter die mehrfach am Tag längere Strecken zurücklegen müssen, können ein Grund für eine Investition sein. Leitfrage: Haben oder planen Sie elektrische Transporter oder LKW? Wenn Sie sich noch unsicher sind, geben Sie doch einfach erstmal eine Abschätzung ein. ",
        "min": 0,
        "max": 100,
        "min_err": "Dieser Wert is leider zu niedrig.",
        "max_err": "Sind Sie sicher? Dieser Wert ist sehr hoch."
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "company_name",
        "type": "text",
        "is_required": true,
        "category": "Kontaktdaten",
        "label": "Unternehmen"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "firstname",
        "type": "text",
        "is_required": true,
        "category": "Kontaktdaten",
        "label": "Vorname"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "lastname",
        "type": "text",
        "is_required": true,
        "category": "Kontaktdaten",
        "label": "Nachname"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "street_nr_private",
        "type": "text",
        "is_required": true,
        "autoComplete": "street-address",
        "category": "Kontaktdaten",
        "label": "Straße und Hausnummer"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "postcode_private",
        "type": "text",
        "is_required": true,
        "category": "Kontaktdaten",
        "label": "Postleitzahl"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "city",
        "type": "text",
        "is_required": true,
        "category": "Kontaktdaten",
        "label": "Stadt"
    },

    {
        "page": "address_information",
        "model": "address_information",
        "name": "email",
        "type": "email",
        "is_required": true,
        "category": "Kontaktdaten",
        "label": "Email"
    },
    {
        "page": "address_information",
        "model": "address_information",
        "name": "telephone",
        "type": "tel",
        "is_required": true,
        "category": "Kontaktdaten",
        "label": "Telefon"
    }
]