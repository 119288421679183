/*
array of pages, questions, in prompt order.
this is mostly JSON but defined in JS to permit logic in page handling.
*/

import fields from '@/Gridty-Fields';
import dropdowns from '@/Gridty-Dropdowns';
import { API } from '@constants/api';
import { ConfigType } from '@/types';
import { findPageIndex, generatedPages } from '@/config/helpers';
const pages = Array.from(generatedPages(fields, dropdowns).values()) as ConfigType['pages'];

export const skipRules = {
  is_heat_required: (data: any) => {
    return findPageIndex({
      redirectPage: 'has_existing_pv',
      isNextPageAccessible: data.transient.is_heat_required,
      pages,
    });
  },
  has_existing_pv: (data: any) => {
    return findPageIndex({
      redirectPage: 'wants_charging',
      isNextPageAccessible: data.transient.has_existing_pv,
      pages,
    });
  },
  has_add_area: (data: any) => {
    return findPageIndex({
      redirectPage: 'wants_charging',
      isNextPageAccessible: data.transient.has_add_area,
      pages,
    });
  },
  wants_charging: (data: any) => {
    return findPageIndex({
      redirectPage: 'address_information',
      isNextPageAccessible: data.transient.wants_charging,
      pages,
    });
  },
  has_public_parking: (data: any) => {
    return findPageIndex({
      redirectPage: 'private_charging',
      isNextPageAccessible: data.transient.has_public_parking,
      pages,
    });
  },
};

const Config: ConfigType = {
  report_api_url: API.calculations.gridty,
  pages,
  skipRules,
};

export default Config;
