import React from 'react';

const LightningLogo = () => {
  return (
    <>
      <svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.25 20.5L18.75 1.75L15 15.5H28.75L11.25 34.25L15 20.5H1.25Z"
          stroke="var(--icon-color-base)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default LightningLogo;
