import { useEffect } from 'react';

export const useThemeListener = (onChangeTheme: (theme: string | null) => void) => {
  useEffect(() => {
    const htmlElement = document.documentElement;

    const observer = new MutationObserver(() => {
      onChangeTheme(htmlElement.getAttribute('data-theme'));
    });

    observer.observe(htmlElement, {
      attributes: true,
      attributeFilter: ['data-theme'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);
};
