import React, { FC } from 'react';

import LightningLogo from './logos/LightningLogo';
import FileLogo from './logos/FileLogo';
import MainContainer from '@shared/MainContainer';

interface Props {
  reportUrl?: string | null;
  error?: string | null;
}

const Report: FC<Props> = ({ reportUrl, error }) => {
  return (
    <MainContainer>
      <div className="mt-[136px] min-w-[560px]">
        <LightningLogo />
        <h1 className="font-bold text-[36px] mt-2">Dankeschön</h1>

        <p className="text-[20px] mt-4" style={{ color: '#6B7280' }}>
          In Kürze erhalten Sie ihr individuelles Exposè zum Download.
          <br /><br />
          Wir simulieren verschiedene Szenarien, daher bitten wir Sie um einen Moment Geduld.
        </p>

        {!reportUrl && !error ? (
          <div className="animate-bounce h-5 my-3"><br />Exposè wird berechnet.</div>
        ) : (
          <>
            <div
              className="flex flex-col items-center justify-center gap-6 w-full h-[248px]"
              style={{
                backgroundColor: 'var(--download-area-color)',
                borderRadius: '5px',
                marginTop: '24px',
              }}
            >
              <FileLogo />
              <div
                className="flex flex-col items-center justify-center w-[350px] h-[56px] text-white"
                style={{
                  backgroundColor: 'var(--download-button-color)',
                  fontWeight: 600,
                  borderRadius: 'var(--download-button-radius)',
                }}
              >
                <a className="text-[20px]" target="_blank" download="bericht.pdf" href={reportUrl || ''}>
                  Jetzt ihr Exposè Downloaden
                </a>
              </div>
            </div>
            <div className="mt-12 flex justify-center underline">
              <a href="/">Zurück zum Start</a>
            </div>
          </>
        )}
      </div>
    </MainContainer>
  );
};

export default Report;
