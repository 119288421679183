import React from 'react';

const ProgramsLogo = () => {
  return (
    <>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.5 23.125H23.125M23.125 23.125H28.75M23.125 23.125V17.5M23.125 23.125V28.75M5 12.5H8.75C9.74456 12.5 10.6984 12.1049 11.4016 11.4016C12.1049 10.6984 12.5 9.74456 12.5 8.75V5C12.5 4.00544 12.1049 3.05161 11.4016 2.34835C10.6984 1.64509 9.74456 1.25 8.75 1.25H5C4.00544 1.25 3.05161 1.64509 2.34835 2.34835C1.64509 3.05161 1.25 4.00544 1.25 5V8.75C1.25 9.74456 1.64509 10.6984 2.34835 11.4016C3.05161 12.1049 4.00544 12.5 5 12.5ZM5 28.75H8.75C9.74456 28.75 10.6984 28.3549 11.4016 27.6516C12.1049 26.9484 12.5 25.9946 12.5 25V21.25C12.5 20.2554 12.1049 19.3016 11.4016 18.5983C10.6984 17.8951 9.74456 17.5 8.75 17.5H5C4.00544 17.5 3.05161 17.8951 2.34835 18.5983C1.64509 19.3016 1.25 20.2554 1.25 21.25V25C1.25 25.9946 1.64509 26.9484 2.34835 27.6516C3.05161 28.3549 4.00544 28.75 5 28.75ZM21.25 12.5H25C25.9946 12.5 26.9484 12.1049 27.6516 11.4016C28.3549 10.6984 28.75 9.74456 28.75 8.75V5C28.75 4.00544 28.3549 3.05161 27.6516 2.34835C26.9484 1.64509 25.9946 1.25 25 1.25H21.25C20.2554 1.25 19.3016 1.64509 18.5983 2.34835C17.8951 3.05161 17.5 4.00544 17.5 5V8.75C17.5 9.74456 17.8951 10.6984 18.5983 11.4016C19.3016 12.1049 20.2554 12.5 21.25 12.5Z"
          stroke="var(--icon-color-base)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ProgramsLogo;
