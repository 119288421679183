import { MouseEvent } from 'react';
// 24 hours 0..23
export const initial_daily = [
  10, 10, 10, 10, 10, 10, 10, 40, 80, 90, 90, 90, 90, 90, 90, 90, 90, 90, 50, 10, 10, 10, 10, 10,
];
// 12 months 0..11
export const initial_monthly =
  // J    F    M    A    M    J   J   A    S    O    N   D
  [75, 75, 75, 75, 75, 75, 75, 65, 75, 75, 75, 65];

const clickLevel = (e: MouseEvent<SVGRectElement>, svg_id: string) => {
  // https://stackoverflow.com/a/75087257/1462529
  // relative to svg, not bar clicked
  const svg = document.getElementById(svg_id) as DOMPointInit;
  let pt = DOMPoint.fromPoint(svg as DOMPointInit); // incomplete point
  pt.x = e.clientX;
  pt.y = e.clientY;
  // The cursor point, translated into svg coordinates
  const cursorpt = pt.matrixTransform((svg as SVGGraphicsElement).getScreenCTM()?.inverse());
  // cursorpt.y range is 0 top to 130 bottom, but clamp to 15 top to 115 bottom
  // then invert to 0..100
  const level = Math.round(100 - Math.min(Math.max(cursorpt.y, 15), 115) + 15);
  // console.log("Level " + level + " element " + Math.floor(cursorpt.x / 30));
  return level;
};

export const handleMouse = (
  e: MouseEvent<SVGRectElement>,
  i: number,
  svg_id: string,
  onChange: (v: string) => void,
  profile: number[],
) => {
  window.teste = e;
  const nd = [...profile];
  nd[i] = clickLevel(e, svg_id);
  onChange(nd.join(','));
};

declare global {
  interface Window {
    teste: MouseEvent<SVGRectElement>;
  }
}
