import React, { ComponentProps, FC, memo } from 'react';
import InputError from '@components/form/InputError';
import InputLabel from '@components/form/InputLabel';
import FieldController from '@components/form/FieldController';
import { Field } from '@/types';
import { IMaskInput } from 'react-imask';

const numericProps: Record<string, Record<string, any>> = {
  integer: {
    thousandsSeparator: ""
  }
}

const NumericInput: FC<Field> = props => {
  const { type, name, ...q } = props;
  const range = q.max ? `${q.min ?? 0}-${q.max}` : undefined;
  const onlyMin = q.min;
  const placeholder = q.placeholder ?? range ?? onlyMin ?? '';

  return (
    <FieldController
      name={`${q.model}.${name}`}
      q={q}
      type={type}
      render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => {
        return (
          <InputLabel q={props}>
            <IMaskInput
              radix=","
              mapToRadix={['.']}
              mask={Number}
              thousandsSeparator="."
              unmask={true}
              onAccept={(value: string) => onChange(value)}
              {...(error && { style: { borderColor: 'var(--input-selected-error) !important' } })}
              className="w-full rounded border border-base-300 p-3 mt-2 text-neutral-800"
              placeholder={placeholder.toString()}
              pattern={q.pattern}
              padFractionalZeros={false}
              normalizeZeros={false}
              {...field}
              inputRef={ref}
              {...numericProps[type]}
            />

            {q.unit && <span className="absolute right-2 text-accent mt-[18px]">{q.unit}</span>}

            <div className="mt-2 mb-2 h-1">{error && <InputError error={error.message} />}</div>
          </InputLabel>
        );
      }}
    />
  );
};

export default memo(NumericInput);
