import { PageConfig } from '@/types';

export const getDefaultValues = (pages: PageConfig[]) => {
  return pages.reduce(
    (result, item) => {
      item.qns.forEach(qn => {
        if (!result[qn.model]) {
          result[qn.model] = {};
        }
      });

      return result;
    },
    {} as Record<string, {}>,
  );
};
