import React, { FC, memo } from 'react';
import InputLabel from '@components/form/InputLabel';
import InputError from '@components/form/InputError';
import FieldController from '@components/form/FieldController';
import { Field } from '@/types';

const YES_ANSWER = 'Ja';
const NO_ANSWER = 'Nein';

const RadioGroup: FC<Field> = props => {
  return (
    <FieldController
      name={`${props.model}.${props.name}`}
      q={props}
      type={props.type}
      defaultValue={false}
      render={({ field: { value, onChange, name }, fieldState: { error } }) => {
        const isYes = value === true;

        return (
          <InputLabel q={props}>
            <div className="relative max-w-52 mt-5">
              <div className="flex flex-col gap-2">
                <label
                  className={`flex gap-2 items-center p-4 border cursor-pointer label-radio ${isYes && 'label-radio-selected'}`}
                >
                  <input
                    name={name}
                    type="radio"
                    id={`${name}_ja`}
                    className="radio radio-main"
                    checked={isYes}
                    onChange={() => onChange(true)}
                  />
                  {YES_ANSWER}
                </label>
                <label
                  className={`flex gap-2 items-center p-4 border cursor-pointer label-radio ${!isYes && 'label-radio-selected'}`}
                >
                  <input
                    name={name}
                    type="radio"
                    id={`${name}_nein`}
                    className="radio radio-main"
                    checked={!isYes}
                    onChange={() => onChange(false)}
                  />
                  {NO_ANSWER}
                </label>
              </div>

              <div className="mt-2 mb-2 h-1">{error && <InputError error={error.message} />}</div>
            </div>
          </InputLabel>
        );
      }}
    />
  );
};

export default memo(RadioGroup);
