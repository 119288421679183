import React from 'react';

const HouseLogo = () => {
  return (
    <>
      <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.75 31V22.875C11.75 21.84 12.59 21 13.625 21H17.375C18.41 21 19.25 21.84 19.25 22.875V31M19.25 31H26.75V1.90833M19.25 31H31.75V13.9167M26.75 1.90833L29.25 1M26.75 1.90833L9.25 8.27333M31.75 13.9167L26.75 12.25M31.75 13.9167L34.25 14.75M1.75 31H4.25M4.25 31H34.25M4.25 31V1H9.25V8.27333M1.75 11L9.25 8.27333"
          stroke="var(--icon-color-base)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default HouseLogo;
