import React from 'react';

const AvatarLogo = () => {
  return (
    <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25.9708 27.2078C24.8063 25.666 23.2996 24.4156 21.5695 23.5551C19.8394 22.6947 17.9331 22.2478 16.0008 22.2495C14.0686 22.2478 12.1623 22.6947 10.4322 23.5551C8.70209 24.4156 7.1954 25.666 6.03084 27.2078M25.9708 27.2078C28.2433 25.1865 29.846 22.5221 30.5695 19.568C31.2929 16.6139 31.1013 13.5097 30.0199 10.667C28.9386 7.82429 27.0187 5.37747 24.5148 3.651C22.0109 1.92454 19.0414 1 16 1C12.9586 1 9.98906 1.92454 7.48518 3.651C4.98129 5.37747 3.06138 7.82429 1.98006 10.667C0.898736 13.5097 0.707088 16.6139 1.43053 19.568C2.15398 22.5221 3.75834 25.1865 6.03084 27.2078M25.9708 27.2078C23.2275 29.6548 19.6769 31.0047 16.0008 30.9995C12.3242 31.0052 8.77456 29.6552 6.03084 27.2078M21.0008 12.2495C21.0008 13.5756 20.4741 14.8474 19.5364 15.785C18.5987 16.7227 17.3269 17.2495 16.0008 17.2495C14.6748 17.2495 13.403 16.7227 12.4653 15.785C11.5276 14.8474 11.0008 13.5756 11.0008 12.2495C11.0008 10.9234 11.5276 9.65166 12.4653 8.71397C13.403 7.77629 14.6748 7.24951 16.0008 7.24951C17.3269 7.24951 18.5987 7.77629 19.5364 8.71397C20.4741 9.65166 21.0008 10.9234 21.0008 12.2495Z"
          stroke="var(--icon-color-base)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default AvatarLogo;
