import { API, METHODS } from '@constants/api';
import { HttpMethod, PageConfig } from '@/types';
import { VITE_NODE_ENV } from '@utils/getEnv';

interface ApiServiceParams {
  endpoint: string;
  method: HttpMethod;
  body?: PageConfig['save_model'];
}

class ApiService {
  static baseUrl: string = API.baseUrl;
  static authToken: string = VITE_NODE_ENV['VITE_AUTH_TOKEN'];

  static async request<D>({ endpoint, method = METHODS.GET, body }: ApiServiceParams): Promise<D> {
    try {
      const response = await fetch(`${this.baseUrl}${endpoint}`, {
        method: method,
        headers: {
          Authorization: `Token ${this.authToken}`,
          'Content-Type': 'application/json',
        },
        body: body ? JSON.stringify(body) : undefined,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  }
}

export default ApiService;
