import QuestionLogo from '@components/logos/QuestionLogo';
import { FC, memo } from 'react';

interface Props {
  title: string;
  tooltip: string;
}

const Title: FC<Props> = ({ title, tooltip }) => (
  <div className="mt-8 flex flex-row items-center gap-2">
    <h2 className="font-bold text-[20px]">{title}</h2>
    <div className="tooltip" data-tip={`${tooltip}`}>
      <QuestionLogo />
    </div>
  </div>
);

export default memo(Title);
