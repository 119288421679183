import { FC, useMemo } from 'react';

interface ProgressBarProps {
  progressLength: number;
  progress: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ progressLength, progress }) => {
  const progressPoints = useMemo(() => new Array(progressLength).fill(0), [progressLength]);

  return (
    <div className="flex flex-row gap-1">
      {progressPoints.map((key, i) => {
        const formula = (i / progressLength) * 100;
        return (
          <div
            className="w-full h-2"
            key={i}
            style={{
              backgroundColor: progress > formula ? 'var(--progress-primary)' : 'var(--progress-secondary)',
              borderTopLeftRadius: i === 0 ? '4px' : undefined,
              borderBottomLeftRadius: i === 0 ? '4px' : undefined,
              borderTopRightRadius: i === progressPoints.length - 1 ? '4px' : undefined,
              borderBottomRightRadius: i === progressPoints.length - 1 ? '4px' : undefined,
            }}
          ></div>
        );
      })}
    </div>
  );
};

export default ProgressBar;
