import React, { FC, PropsWithChildren } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Field } from '@/types';

interface Props {
  q: Field;
}

const InputLabel: FC<PropsWithChildren<Props>> = ({ q, children }) => {
  return (
    <label key={q.name} htmlFor={q.name} className="input-label">
      <span>
        {q.label}
        {q.is_required && '*'}
        {q.description && (
          <span className="tooltip tooltip-right" data-tip={q.description}>
            <InformationCircleIcon className="icon-color" />
          </span>
        )}
      </span>
      <div className="relative">{children}</div>
    </label>
  );
};

export default InputLabel;
