import React, { FC, memo } from 'react';
import CustomField from '@components/form/CustomField';
import TextInput from '@components/form/TextInput';
import RadioGroup from '@components/form/RadioGroup';
import Select from '@components/form/Select';
import { Field } from '@/types';
import NumericInput from '@components/form/NumericInput';

interface Props {
  inputs: Field[];
}

const FIELDS_MAPPING = {
  number: NumericInput,
  integer: NumericInput,
  float: NumericInput,
  tel: TextInput,
  date: TextInput,
  email: TextInput,
  text: TextInput,
  checkbox: RadioGroup,
  select: Select,
  custom: CustomField,
  array: CustomField,
};

const Form: FC<Props> = ({ inputs }) => {
  return (
    <div className="flex flex-col w-full max-w-lg">
      {inputs.map(({ type, name, model, ...q }: any) => {
        const Component = FIELDS_MAPPING[type as keyof typeof FIELDS_MAPPING];

        if (!Component) return <div key={q.name}>Unknown type {type}</div>;

        return <Component key={name} name={name} model={model} type={type} {...q} />;
      })}
    </div>
  );
};

export default memo(Form);
