import React, { FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { useThemeListener } from '@hooks/useThemeListener';

const ThemeContext = React.createContext<{ theme: string | null }>({
  theme: localStorage.getItem('theme'),
});

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = useState<string | null>(localStorage.getItem('theme'));

  const onChangeTheme = (theme: string | null) => {
    setTheme(theme);
  };

  useThemeListener(onChangeTheme);

  const value = useMemo(() => ({ theme }), [theme]);

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => useContext(ThemeContext);

export default ThemeProvider;
